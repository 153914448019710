<!--:totalRecords="totalRecords"
:lazy="true"
--><!--:lazy="true"-->
<!--@filter="onFilter($event)"-->
<!--:filters="filters"-->
<!--@filter="onFilter($event)"-->
<!--stateStorage="session"
    stateKey="dt-state-demo-session"-->
    <template>
  <DataTable
      v-if="model"
    :value="listDataService"
    :lazy="true"
    class="p-datatable-responsive"
    selectionMode="single"
    data-key="id"
    @page="onPage($event)"
    :paginator="pagination"
    :totalRecords="totalRecords"
    :rows="paginationRows"
    :loading="loading"
    :scrollable="(scrollHeight !== '' ? true : false) "
    :scrollHeight="scrollHeight"
    :autoLayout="true"
    filterMatchMode="startsWith"
    :first="firstRecordIndex"
    :rowsPerPageOptions="$constants.LIST_OPTION_COMMON.PER_PAGE_OPTIONS"
    :currentPageReportTemplate=" $constants.LIST_OPTION_COMMON.TEXT_TOTAL_ITEM+' {totalRecords}'"
    :paginatorTemplate="$constants.LIST_OPTION_COMMON.PAGINATOR_TEMPLATE"
  >

    <div class="div_search">
      <div class="div_date div_search_mobi div_pc">
        <label class="p-col-fixed txt-right text_align label_company">Xem thống kê </label>
        <Calendar
            class=" margin input_width border_color Calendar width_mobi"
            dateFormat="dd/mm/yy"
            placeholder="DD/MM/YYYY - DD/MM/YYYY"
            id="date"
            v-model="filters['date_filter']"
            :showIcon="true"
            :showOnFocus="true"
            :monthNavigator="true"
            :yearNavigator="true"
            yearRange="2000:2030"
            :manualInput="false"
            selectionMode="range"
            appendTo="body"
            :showClear="true"
            :showButtonBar="true"
            @clear-click="onFilterOutside('DateRange','date_filter')"
            @date-select="onFilterOutside('DateRange','date_filter')"
        />

      </div>

      <div class="div_company div_search_mobi div_pc">
        <label class="p-col-fixed txt-right text_align label_company ">Công ty</label>
        <Dropdown
            id="selectType"
            @change="onFilterOutside('select','company_filter')"
            class="input_text width_mobi "
            v-model="filters['company_filter']"
            :options="company"
            optionLabel="text_lable"
            optionValue="id"
            placeholder="Chọn" >
          <template #option="slotProps">
            <div class="p-dropdown-company-option">
              <span><em v-if="slotProps.option.code"><b>{{slotProps.option.code}}</b> - </em>{{slotProps.option.name}}</span>
            </div>
          </template>
        </Dropdown>
      </div>
      <!--      <div class="button_import">
              <Button v-if="display == true" v-on:click="show" label="Xuất excel" class="p-button-success" />
            </div>-->
    </div>


    <Dialog
        header="Xuất excel"
        :visible.sync="showDeleteDialog"
        :contentStyle="{overflow: 'visible'}"
        :modal="true"
    >
      <div class="div_date margin_botton">
        <label class="p-col-fixed txt-right text_align label_company">Thời gian bảng quyết toán dịch vụ</label><br>
        <Calendar style="width: 100%!important;" class=" margin input_width border_color Calendar width_input_popup" dateFormat="dd-mm-yy" placeholder="DD-MM-yyyy"  id="date" v-model="date" :showIcon="true" />
      </div>
      <div class="p-card-field margin_botton">
        <label class="p-col-fixed txt-right label_company">Kính gửi <span style="color: red">*</span></label>
        <div class="p-col">
          <InputText style="width: 100%!important;" class="p-col-width input_text border_color width_input_popup" maxlength="255" v-model="import_excel.dear"  id="name" type="text"  placeholder="Nhập " />
        </div>
      </div>
      <div class="p-card-field width_size margin_botton">
        <label class="p-col-fixed txt-right margin dif label_company" id="label">Chi phí chênh lệch kì trước<span style="color: red">*</span></label>
        <div class="p-inputgroup input-dif">
          <InputText style="width: 100%!important;" class="p-col-width input_distance width_dif border_color width_input_popup" type="text"  maxlength="4" v-model="import_excel.cost" id="size" placeholder="Nhập"  />
          <span class="p-inputgroup-addon" id="km">đ</span>
        </div>
      </div>
       <strong></strong>
      <template
          #footer
      >


        <Button
            label="Hủy"
            @click="showDeleteDialog = false"
            icon="pi pi-times"
            class="p-button-warning callback-btn"
        />
        <Button label="Xuất" icon="pi pi-check" class="main-style-button" />
      </template>
    </Dialog>


    <Column
      v-for="c in model.getDisplayFields()"
      :key="c.key"
      :field="getField(c)"
      :header="getLabel(c)"
      :ref="getField(c)"
      :filterMatchMode="getFilterMatch(c)"
      :sortable="getSortable(c)"
      :bodyClass="(c.class ? 'txt-' + c.class : '')"
      :bodyStyle="getHeaderStyle(c)"
      :headerStyle="getHeaderStyle(c)"
      :headerClass="(c.class ? 'txt-' + c.class : '')"
    >
      <template #body="slotProps">
        <!--{{c}}-->
        <span class="responsive-label">{{ getLabel(c) }}</span>
        <img
          v-if="getType(c) == 'Image'"
          :src="getData(c, slotProps.data)"
          style="width: 40px; heigth: 40px"
        />
        <div
          v-else-if="c.type == 'Text'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>
        <div
          v-else-if="getType(c) == 'Custom' || getType(c) == 'Icon'"
          v-html="getData(c, slotProps.data)"
          :style="(c.style ? c.style : '')"
        ></div>

        <Checkbox
          v-else-if="getType(c) == 'Checkbox'"
          v-model="slotProps.data[slotProps.column.field]"
          :binary="true"
          @change="onInputChange($event, slotProps.data, c)"
        />
        <div v-else>
          <div v-if="c.custom">
            <Avatar v-if="c.custom == 'image'" :image="getData(c, slotProps.data)" size="xlarge"  />
            <div v-if="c.custom == 'SelectionText'"  v-html="callbackDataSelect( c ,slotProps.data)"></div>
          </div>
          <span v-else v-html="getData(c, slotProps.data)" :class="getCustomClass(c, slotProps.data[c.key], slotProps.data)"></span>
        </div>

      </template>
      <template v-if="c.filter" #filter>
        <InputText
          v-if="c.filter.type == 'input'"
          type="text"
          v-model="filters[$CommonList.getFieldFilter(c)]"
          class="p-column-filter"
          :placeholder="getFilterPlaceHolder(c)"
          @keyup="onFilter($event,c.filter.type,c.key)"
        /><!-- :keyup="onFilter(c.filter.type,c.key)"-->
        <!--v-model="filters[getField(c)]"-->
        <Dropdown
          :key="countDropdown"
          v-if="c.filter.type == 'select'"
          v-model="filters[getField(c)]"
          :options="getFilterOptions(c.key)"
          :optionLabel="c.filter.optionLabel"
          :optionValue="c.filter.optionKey"
          :placeholder="getFilterPlaceHolder(c)"
          class="p-column-filter"
          :showClear="true"
          appendTo="body"
          @change="onFilter($event,c.filter.type,c.key)"
        >
          <template #option="slotProps">
            <div class="p-clearfix">
              <span
                :class="getCustomClass(c, slotProps.option[c.filter.optionKey], slotProps.data)"
                >{{ slotProps.option[c.filter.optionLabel] }}</span
              >
            </div>
          </template>
        </Dropdown>
<!--        <Calendar
          v-if="c.filter.type == 'DateRange'"
          :showOnFocus="false"
          :monthNavigator="true"
          :yearNavigator="true"
          yearRange="2000:2030"
          dateFormat="yy-mm-dd"
          id="date_from"
          v-model="filters[getField(c)]"
          class="p-column-filter"
          :showIcon="true"
          selectionMode="range"
          appendTo="body"
          :showClear="true"
          @date-select="select_date($event,getField(c))"
          :manualInput="false"
          />-->
        <Calendar
            v-if="c.filter.type == 'DateRange'"
            :showOnFocus="true"
            :monthNavigator="true"
            :yearNavigator="true"
            yearRange="2000:2030"
            dateFormat="dd/mm/yyyy"
            v-model="filters[getField(c)]"
            class="p-column-filter"
            :showIcon="true"
            selectionMode="range"
            appendTo="body"
            :showClear="true"
            :showButtonBar="true"
            @clear-click="onFilter($event,c.filter.type,c.key)"
            @date-select="onFilter($event,c.filter.type,c.key)"
            :manualInput="false"
        />
      </template>
    </Column>
    <template #empty>
      {{ $t('datatable.empty') }}
    </template>
    <template #loading>
      {{ $t('datatable.loading') }}
    </template>
  </DataTable>
</template>

<script>
import moment from "moment-timezone";
import DataServices from "@/core/DataServices";
//import ApiRepository from "@/core/ApiRepository";
//import gql from "graphql-tag";
// import DataServices from "@/core/DataServices";
import AuthenticationApp from "@/core/AuthenticationApp";
export default {
  props: {
    model: Object,
    models: Object,
    tableData: Array,
    getFilterOptions: Function,
    actions: Object,
    options: Array,
    whereKeyList: Object,
  },
  data() {
    return {
      //filters: {},
      group_channel: {
        search: '',
      },
      import_excel:{
        dear:'',
        cost:''
      },
      showDeleteDialog: false,
      list_report_summary:{},
      company:[],
      SelectedCompany:null,
      countDropdown: 0,
      pagination: true, // true cho phép phân trang
      paginationRows: this.$constants.LIST_OPTION_COMMON.PAGINATION_ROWS_DEFAULT, // số dòng trên 1 trang
      scrollHeight: '', // chiều cao của trang danh sách
      loading: true,
      rowsPerPageOptions: [10, 20, 50, 100],
      first: 0,
      totalRecords: 120,
      totalRecords2: 12,
      test: null,
      listDataService:null,
      filters: {},
      filtersCustom: [],
      lazyParams: {},
      page_transaction:null,
      firstRecordIndex:0
    };
  },
  async created() {

    console.log("modelmodelmodelmodelmodelmodelmodelmodelmodel:")
    /*console.log("sajsaskasa",this.dataList())
    console.log("apiiiii",this.models)
    console.log(this.tableData);
    this.test = this.tableData;*/


    //this.listDataService = await this.dataList();
  },
  async mounted () {

    if (this.$commonFuction.isEmpty(this.filters['date_filter'])){
      const firstDayOfMonth = moment().startOf('month').format("YYYY-MM-DD");
      const currentDate = moment().format("YYYY-MM-DD");
      this.filters['date_filter'] =  [new Date(firstDayOfMonth), new Date(currentDate)]
      //this.filtersCustom['date_filter'] = await this.$CoreService.convertFilter('DateRange','date_filter',this.filters);
     await this.initFilterOutside('DateRange','date_filter')
    }

    var whereAttach ={}
    var listDisplayCompany = AuthenticationApp.getListCompany();
    if (listDisplayCompany != null) {
      //order_shipping(where: {order_shipping_items: {order: {company_id: {_in: ""}}}})
      whereAttach.id =  {_in: listDisplayCompany};
    }
    var listData =await this.$CoreService.getCompanyFilter(whereAttach);
    this.company = listData;
    if (this.company.length > 0){
      this.filters['company_filter'] = this.company[0]["id"]
      await this.initFilterOutside('select','company_filter')
    }

    this.lazyParams = {
      first: 0,
      rows: this.paginationRows,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    //  filtersTmp:this.filters
    };
    if (!this.$commonFuction.isEmpty(this.$route.query)){
      if (!this.$commonFuction.isEmpty(this.$route.query.page_transaction)){
        this.page_transaction = this.$route.query.page_transaction;
        this.lazyParams = (localStorage.getItem(this.page_transaction)) ? JSON.parse(localStorage.getItem(this.page_transaction)) : {};
        console.log("this.lazyParamsthis.lazyParams:"+this.page_transaction,this.lazyParams);
         this.firstRecordIndex = this.lazyParams.first;
        console.log("this.lazyParams:22222222",this.lazyParams);
       /* if (this.lazyParams){
          console.log("this.lazyParams.filters",this.lazyParams.filters);

        }*/
      }
    }


    this.loading = false
    this.countDropdown++;
    this.onLazyEvent();
  },
  methods: {

    show(){
      this.showDeleteDialog = true
    },

    Search(){
      console.log("cogoidnko")
      this.onLazyEvent()
    },
    Add(){
      console.log("cogoidnko")
      this.$router.push({path: '/admin/list-group_channel/add'});
    },


    callbackDataSelect(c,data){
      console.log("channel11 company",data);
      var text = "";
      switch(c.key) {
        // key la cai name
        case 'is_enough_documents':
          if(data.is_enough_documents == true){
            text = `Có`;
          }else{
            text = `Không`;
          }
          break;
        case 'active':
          if(data.active == true){
            text = `<i  style="color: green" class="pi pi-check icon"></i>`;
          }else{
            text = `<i style="color: red" class="pi pi-times icon"></i>`;
          }
          break;
        case 'accountant_confirms':
          text = data.accountant_confirms_text ? data.accountant_confirms_text : "-";
          break;
        case 'company_id':
          console.log("channelcompany99",data);
          console.log("channelcompany11",data.group_channel_companies.length);
          var list_company =  data.group_channel_companies ? data.group_channel_companies:[]
          for (var j = 0 ; j < list_company.length; j++)
          {
            if(j != list_company.length -1  )
            {
              text  +=  list_company[j].company.code ? list_company[j].company.code + "; "  : "-";
            }
            else {
              text += list_company[j].company.code ? list_company[j].company.code  : "-";
            }

          }
          break;

        case 'channel_id':
          var list_channel =  data.channel.code ? data.channel.code:[]
            text = list_channel
          console.log("sang1111",list_channel.length)
          console.log("sang1111",list_channel)
          break;
        default:
      }
      return text;
    },
    select_date(event,col_name) {
      if (this.filters[col_name][0] !== null && this.filters[col_name][1] !== null) {
        this.$emit("filter_date", {
          data: this.filters[col_name],
          col: col_name
        });
      }
    },
    getLabel(col) {
      if (col.label) return col.label;
      else return col.name;
    },
    getType(col) {
      if (col.display?.list?.mode == "custom") {
        return "Custom";
      }
      switch (col.type) {
        case "String":
        case "Text":
        case "Computed":
          if (col.inputOptions?.mode == "Image") {
            return "Image";
          }
          if (col.inputOptions?.mode == "selection") {
            return "Select";
          }
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          }
          if(col.inputOptions?.mode == 'icon') {
            return "Icon";
          }
          return "Input";
        case "numeric":
        case "ShadowField":
        case "number":
          if (col.inputOptions?.mode == "currency") {
            return "Currency";
          } else if (col.inputOptions?.mode == "percentage") {
            return "Percentage";
          } else {
            return "Number";
          }
        case "Selection":
          return "Select";
        case "Checkbox":
          return "Checkbox";
        default:
          return col.type;
      }
    },
    cannotEdit(col) {
      if (col.edit && col.edit.edit == false) return true;
      return false;
    },
    onInputChange(value, data, f) {
      if (f.onChange)  f.onChange(data).bind(this);
      this.$emit("onDataChange", data, f);
    },
    toggle(event) {
      this.$refs.op.toggle(event);
    },
    getCustomClass(col, data, rowData) {
      if (col.displayClass) return col.displayClass(data, rowData);
      return "";
    },
    getField(col) {
      return col.key;
    },
    getHeaderStyle(col) {
      var style = "";
      if (col.width) style = "width: " + col.width + "em;";
      else if (col.name === "stt" || col.name === "STT") style = "width: 3em;";
      else if (col.type === "image" || col.type === "Image") style = "width: 6em;";
      return style;
    },
    getData(col, row) {
      console.log("colcolcol:",col);
      console.log("rowrowrowrow:",row);
      var value = '';
      if (col.type == "Date" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("DD/MM/yyyy");
      } else if (col.type == "Datetime" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format(" DD/MM/yyyy HH:mm:ss");
      } else if (col.type == "CustomDate" && row[col.key]) {
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm - DD/MM/yyyy");
      } else if(col.type == "Hour" && row[col.key]){
        value = moment.tz(row[col.key], "Asia/Ho_Chi_Minh").format("HH:mm");
      } else if (col.type == "Selection") {
        var lists = [];
        if (col.options !== undefined) {
          lists = col.options;
        } else if (col.models !== undefined && this.options[col.key] !== undefined) {
          lists = this.options[col.key];
        }
        if (lists && lists.length) {
          var objResult = lists.filter(a => a.value == row[col.key]);
          if (objResult !== undefined && Object.keys(objResult) && Object.entries(objResult).length) {
            value = objResult[Object.keys(objResult)].label;
          }
        }
      } else {
        if (col.type == "Text" && row[col.key] && row[col.key].length > 100) {
          var result = row[col.key].substr(0, row[col.key].lastIndexOf(" ", 100)) + "...";
          value = result;
        }
        if (this.getType(col) == "Currency") {
          if (row[col.key] == null) row[col.key] = 0;
          value = this.formatCurrency(row[col.key]);
        }
        if (this.getType(col) == "Percentage") {
          if (row[col.key] == null) row[col.key] = `0%`;
          value = row[col.key] + "%";
        }
        value = row[col.key];
      }
      if (col.style) value = '<abbr style="' + col.style + '">' + value + '<abbr/>';
      else if (col.options) {
        var filtered = col.options.filter( a => a.value  === row[col.key]);
        if (Object.keys(filtered) && Object.entries(filtered).length && filtered[0].style) {
          value = '<abbr style="' + filtered[0].style + '">' + value + '<abbr/>';
        }
      }
      return value
    },
    getFilterMatch(col) {
      var filterMatch = "startWith";
      if (col.filter && col.filter.match) {
        filterMatch = col.filter.match;
      }
      return filterMatch;
    },
    getFilterPlaceHolder(col) {
      if (col.filter.placeHolder) {
        return col.filter.placeHolder;
      } else if (col.filter.type == "select") {
        return `Chọn `;
      } else if (col.filter.type == "input") {
        return `Nhập`;
      } else {
        return `Lọc`;
      }
    },
    getSortable(col) {
      return col.sortable && col.sortable == true ? true : false;
    },
    getIcon(iconName) {
      return `pi pi-${iconName}`;
    },
    getDisabled(disabled = false) {
      return disabled;
    },
    actionsClicked(key, action, rowData) {
      this.$emit("action-click", {
        originalEvent: event,
        action: action,
        key: key,
        data: rowData,
        lazyParams:this.lazyParams
      });
    },
    getClass(type) {
      switch (type) {
        case "add":
          return "p-button-add";
        case "delete":
          return "p-button-danger";
        case "edit":
          return "p-button-edit";
        case "adddebt":
          return "p-button-edit"
        case "add-Debt":
          return "p-button-add";
        case "editcustom":
          return "p-button-edit"
        case "addcustom":
          return "p-button-add";
      }
    },
    formatCurrency(value) {
      return value.toLocaleString("vi-VN", {
        style: "currency",
        currency: "VND"
      });
    },
   async dataList(lazyParams) {
      console.log('lazyParams');
      console.log('lazyParams:',lazyParams);
     var offset = lazyParams.first;
     var limit = lazyParams.rows;
     console.log("this.model.tableName:",this.model.tableName)
     var gqLQueryList = DataServices.getList("order_docs");
     // console.log("ggLQueryList")
     // console.log(gqLQueryList)
     var where = {
       deleted: {_eq: false},
       //transfer_docs_date: { _is_null: false }
     };
     var filters = lazyParams.filters;


     if (this.group_channel.search != "" && this.group_channel.search != undefined)
     {
       where.name =  {
         _iregex: this.$commonFuction.search(this.group_channel.search),
       };
     }
     /*if (!this.$commonFuction.isEmpty(this.date)) {
       if (!this.$commonFuction.isEmpty(this.date[1]) && !this.$commonFuction.isEmpty(this.date[0])) {
         dataRequest["date_order"] = {value: {to: this.date[1], from: this.date[0]}, type_input: 'DateRange'}
       }
     }
     if (!this.$commonFuction.isEmpty(this.SelectedCompany)) {
       dataRequest["company_id"] = {value: this.SelectedCompany, type_input: 'select'}
     }*/
     console.log('filters')
     console.log(filters)
     if (filters){
       for (const [key, value] of Object.entries(filters)) {
         if (!this.$commonFuction.isEmpty(value.value)) {
           if (value.type_input == 'input') {
             if (key == 'code'){
               where.code =  {
                 _iregex: this.$commonFuction.search(value.value),
               };
             }else  if (key == 'doc_code'){
               where.doc_code =  {
                 _iregex: this.$commonFuction.search(value.value),
               };
             }
             else  if (key == 'customer_code'){
               where.customer_code =  {
                 _iregex: this.$commonFuction.search(value.value),
               };
             }
           }
           else if (value.type_input == 'select') {
             if (key == 'delivery_vehicle') {
               where['delivery_vehicle'] = {_eq: value.value};
             }else if (key == 'area_type') {
               where['area_type'] = {_eq: value.value};
             }else if (key == 'company_filter') {
              /* if (this.$commonFuction.isEmpty(where.order)){
                 where.order = {}
               }*/
               if (value.value != '-1'){
                 where.company_id = {_eq: value.value}
               }
             }else  {
               where[key] = {_eq: value.value};
             }
           }else if (value.type_input == 'DateRange'){
             if (key == 'date_filter'){
               if(value.value !== undefined && value.value !== null){
                 // console.log("dsadasdas");
                 where['date_order'] = {'_gte': value.value.from,'_lt': value.value.to}
               }
             }else {
               if(value.value !== undefined && value.value !== null){
                 // console.log("dsadasdas");
                 where[key] = {'_gte': value.value.from,'_lt': value.value.to}
               }
             }
           }
         }
       }
     }

/*
       var dataRequest = {
         //order_id: this.model.id,
       };
       const headers = {
         // "Authorization": "Bearer "+(await this.$AuthenticationApp.getToken()),
       };
       var list_report_summary = await ApiRepository.post('/_api/order/api-aggregate-costs', dataRequest, {headers});
       var STATUS_NEXT_ORDER = (list_report_summary["data"]["data"]) ? list_report_summary["data"]["data"]:{}
       this.list_report_summary = STATUS_NEXT_ORDER
     console.log("where",where)
       console.log("list_report_summary1:",this.list_report_summary)

     return this.list_report_summary*/
     var resData = await this.$apollo.mutate({
       mutation: gqLQueryList,
       variables: {
         where_key:where ,
         offset:offset,
         limit:limit,
         orderBy:{updated_at: 'desc'}
       }
     });
     var listData = resData.data["order"];

     var gqLQueryCount = DataServices.countData("order_docs");
     var countRes = await this.$apollo.mutate({
       mutation: gqLQueryCount,
       variables: {
         where_key:where
       }
     });
     var total = countRes.data['order_aggregate']['aggregate']['count'];

     var dataRes = {
       list_data: listData,
       total: total,
     };

     return dataRes;
    },
    onLazyEvent() {
      this.lazyParams.filters = this.filtersCustom;
      console.log('this.lazyParams:', this.lazyParams)
      this.loading = true;
      var that = this;
      this.dataList(this.lazyParams).then(async data => {
        console.log("datadata:", data)
        var where_status = {code: {_eq: "ST014"}};
        var list_data_status = await that.$CoreService.getListData('order_status', where_status, 0, 1, null);
        var where_status_docs_comfirm = {code: {_eq: "ST015"}};
        var list_data_docs_comfirm = await that.$CoreService.getListData('order_status', where_status_docs_comfirm, 0, 1, null);
        var stats_docs_id = null
        if (list_data_status.length>0){
          stats_docs_id = list_data_status[0]["id"]
        }
        var stats_docs_comfirm_id = null
        if (list_data_docs_comfirm.length>0){
          stats_docs_comfirm_id = list_data_docs_comfirm[0]["id"]
        }
        // var count = 0;
        if (data.list_data.length < 1) {
          that.listDataService = data.list_data;
          that.totalRecords = data.total;
          that.loading = false;
        }
         var stt = this.lazyParams.first;
        var list_id_order =  []
        for (var i = 0; i < data.list_data.length; i++) {
           stt++;
           data.list_data[i].stt = stt;
          if(data.list_data[i].rel_object_group_uses !== undefined && data.list_data[i].rel_object_group_uses !== null){
              data.list_data[i].name_group_filter = data.list_data[i].rel_object_group_uses.group_id;
          }else{
            data.list_data[i].name_group_filter = 0;
          }
          list_id_order.push(data.list_data[i].id)
        }
        var list_map_status = {}
        var list_map_status_docs_comfirm = {}
        if (list_id_order.length>0 && stats_docs_id && stats_docs_comfirm_id){
          var where_h = {order_id: {_in: list_id_order},order_status_id: {_eq: stats_docs_id}};
          //var order_h = {ordinal: 'desc'};
          var list_data = await that.$CoreService.getListData('history_status_order', where_h, 0, 100, null);
          if (list_data.length>0){
            for (var k=0;k<list_data.length;k++){
               var order_id = list_data[k]["order_id"];
              list_map_status[order_id] = list_data[k]
            }
          }

          var where_h_docs_comfirm = {order_id: {_in: list_id_order},order_status_id: {_eq: stats_docs_comfirm_id}};
          //var order_h = {ordinal: 'desc'};
          var list_data_h_docs_comfirm = await that.$CoreService.getListData('history_status_order', where_h_docs_comfirm, 0, 100, null);
          if (list_data_h_docs_comfirm.length>0){
            for (var h=0;h<list_data_h_docs_comfirm.length;h++){
              var order_id_comfirm = list_data_h_docs_comfirm[h]["order_id"];
              list_map_status_docs_comfirm[order_id_comfirm] = list_data_h_docs_comfirm[h]
            }
          }
        }
        for (var g = 0; g < data.list_data.length; g++) {
          var order_id_key = data.list_data[g]["id"];
          data.list_data[g].warehouse_time_key = null
          if (list_map_status[order_id_key]){
            var item_status = list_map_status[order_id];
            data.list_data[g].warehouse_time_key  = item_status.created_at;
          }
          data.list_data[g].accountant_confirms_text  = "Không";
          if (list_map_status_docs_comfirm[order_id_key]){
            data.list_data[g].accountant_confirms_text  = "Có";
            var item_status_docs_comfirm = list_map_status[order_id];
            data.list_data[g].date_accountant_confirms_key  = item_status_docs_comfirm.created_at;
          }
        }
        that.listDataService = data.list_data;
        that.totalRecords = data.total;
        that.loading = false;
        that.model.processData(data.list_data);
      });
    },
    onPage(event) {
      console.log("eventeventeventevent",event);
      this.lazyParams = event;
      this.onLazyEvent();
    },
    async onFilter(event,type_input,key) {
      //this.loading = true;
      this.tableData = this.test;
      console.log('this.test',this.test);
      console.log('this.test',this.tableData);
      console.log('tableData',this.whereKeyList);
      console.log('filters:',this.filters);
      if(type_input == "DateRange"){
        if(this.filters[key] !== null && this.filters[key][1]== null){
          return false;
        }
      }
      // this.filtersCustom[key] = {
      //   type_input:type_input,
      //   value:this.filters[key],
      // };

      // console.log('this.filtersCustom:',this.filtersCustom);
      // this.lazyParams.first = 0;
      // this.onLazyEvent();
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input,key,this.filters);
      this.lazyParams.first = 0;

      this.onLazyEvent();

      /*setTimeout(() => {
        this.customers = this.datasource.slice(event.first, event.first + event.rows);
        this.loading = false;
      }, 500);*/
    },
    async initFilterOutside(type_input,key) {
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input,key,this.filters);
      this.lazyParams.first = 0;
    },
    async onFilterOutside(type_input,key) {
      //this.loading = true;
      /*this.tableData = this.test;
      console.log('this.test',this.test);
      console.log('this.test',this.tableData);
      console.log('tableData',this.whereKeyList);
      console.log('filters:',this.filters);*/
      /*if(type_input == "DateRange"){
        if(this.filters[key] !== null && this.filters[key][1]== null){
          return false;
        }
      }*/
      // this.filtersCustom[key] = {
      //   type_input:type_input,
      //   value:this.filters[key],
      // };

      // console.log('this.filtersCustom:',this.filtersCustom);
      // this.lazyParams.first = 0;
      // this.onLazyEvent();
      this.filtersCustom[key] = await this.$CoreService.convertFilter(type_input,key,this.filters);
      this.lazyParams.first = 0;

      this.onLazyEvent();

      /*setTimeout(() => {
        this.customers = this.datasource.slice(event.first, event.first + event.rows);
        this.loading = false;
      }, 500);*/
    },
  }
};
</script>
<style lang="scss">
@media screen and (min-width: 1000px) {
  .p-paginator-bottom {
    padding: 0 !important;
  }
  .icon {
    font-size: 1.5rem;
    align-items: center;
    justify-content: center;
    display: flex;
  }
  .manager-title {
    font-size: 24px !important;
  }

  //do rong cot
  .txt-width_135 {
    max-width: 135px !important;
  }
  .txt-width_270 {
    max-width: 270px !important;
  }
  //.search {
  //  display: none !important;
  //}
  //.responsive-label {
  //  display: none !important;
  //}
  .div_button_add {
    display: none !important;
  }
  .p-column-filter{
    width: 100%;
  }
  txt-width_135{
    max-width: 135px!important;
  }
  .p-dropdown-label{
    height: 45px!important;
  }
  .input_text{
    width: 372px!important;
    height: 48px!important;
    //padding: 12px!important;
    border: 1px solid #D0D4D9;
  }
  .Calendar{
    width: 372px!important;
    //height: 48px!important;
  }
  .div_company{
    margin: 0px 0px 0px 12px!important;
  }
  .div_date{
    //margin: 0px 0px 12px 0px!important;
  }
  .label_company{
    font-weight: bold!important;
    padding: 0px !important;
  }
  .p-inputtext{
    padding: 10px!important;
  }
  .div_search{
    display: flex!important;
    margin-bottom: 12px!important;
  }
  .p-col{
    padding: 0px!important;
  }
  .margin_botton{
    margin-bottom: 22px!important;
  }
  .p-button-success{
    background: #C40380;
  }
div.p-dialog{
  width: 600px!important;
}
.width_input_popup{
  width: 100%!important;
}
  .div_pc{
    width: 372px!important;
  }


}








// responsive
// list
@media screen and (max-width: 600px) {
  .layout-wrapper .layout-sidebar{
    left: -1000px;
  }
  .p-component *{
    text-align: right!important;
  }
  .responsive-label{
    display: block!important;
  }
  .responsive-label {
    font-weight: bold!important;
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;


  }
  .p-column-title{
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;
    //padding: 11px!important;

  }
  .common{
    display: flex!important;
    width: 50%!important;
    justify-content: space-between!important;
  }
  .p-button-edit{
    margin-right: 13px!important;
    width: 38px!important;
    height: 32px!important;
  }
  .p-button-danger{
    text-align: right!important;
    width: 38px!important;
    height: 32px!important;
  }
  .action{
    display: flex!important;
  }
  // tim kiem
  .search{
    padding-left: 0px!important;
    display: block!important;
  }
  .input_search{
    width: 100%!important;
    height: 48px!important;
    border: 1px solid #D0D4D9;
  }
  .text_align{
    display: block!important;
    text-align: left!important;
    font-size: 16px!important;
    font-weight: bold;
  }
  // button add
  .div_button_add{
    display: flex!important;
    justify-content: end!important;
    margin-top: 12px!important;
  }
  .button_add{
    width: 38px!important;
    height: 38px!important;
    background-color: #00973D;
    margin-left: 12px!important;
  }
  #search{
    padding:12px!important;
    text-align: left!important;
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td{
    display: flex !important;
    //align-items: end!important;
    justify-content: space-between!important;
  }



//  ------------------------------------------------
  .div_search{
    display: block!important;
    margin-bottom: 12px!important;
  }
.width_mobi{
  width: 100%!important;
  height: 45px!important;
}
  .p-button-success{
    margin: 12px 0px 0px 0px !important;
    background: #C40380 !important;
    border: 1px solid #C40380!important;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child{
    border: none!important;
  }
  .label_company{
    text-align: left!important;
  }
  .p-col-fixed{
    padding: 0px!important;
    margin-top: 7px!important;
  }
label{
  margin: 0px 0px 12px 0px!important;
}




}





@media screen and (max-width: 950px) {
  .layout-wrapper .layout-sidebar{
    left: -1000px!important;
  }
  .p-component *{
    text-align: right!important;
  }


  .responsive-label {
    font-weight: bold!important;
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;
  }
  .p-column-title{
    display: flex!important;
    text-align: left!important;
    margin-right: 50px!important;
    width: 50%!important;
    justify-content: space-between!important;
    align-items: center!important;
    //padding: 11px!important;
  }
  .common{
    display: flex!important;
    width: 50%!important;
    justify-content: space-between!important;
  }
  .p-button-edit{
    margin-right: 13px!important;
    width: 38px!important;
    height: 32px!important;
  }
  .p-button-danger{
    text-align: right!important;
    width: 38px!important;
    height: 32px!important;
  }
  .action{
    display: flex!important;
  }


  // tim kiem
  .search{
    display: block!important;
    padding-left: 0px!important;
  }
  .input_search{
    width: 100%!important;
    height: 48px!important;
    border: 1px solid #D0D4D9;
  }
  .text_align{
    display: block!important;
    text-align: left!important;
    font-size: 16px!important;
    font-weight: bold;
  }
  // button add
  .div_button_add{
    display: flex!important;
    justify-content: end!important;
    margin-top: 12px!important;
  }
  .button_add{
    width: 38px!important;
    height: 38px!important;
    background-color: #00973D;
    margin-left: 12px!important;
  }
  #search{
    padding:12px!important;
    text-align: left!important;
  }

  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td{
    display: flex !important;
    //align-items: end!important;
    justify-content: space-between!important;
  }

//  ----------------------------------------------------
  .div_search{
    display: block!important;
    margin-bottom: 12px!important;
  }
  .width_mobi{
    width: 100%!important;
    height: 45px!important;
  }
  .p-button-success{
    margin: 12px 0px 0px 0px !important;
    background: #C40380 !important;
    border: 1px solid #C40380!important;
  }
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td:last-child{
    border: none!important;
    font-weight: bold!important;
  }
  .label_company{
    text-align: left!important;
  }
  .p-col-fixed{
    padding: 0px!important;
    margin-top: 7px!important;
  }
  label{
    margin: 0px 0px 12px 0px!important;
  }

}



</style>

<style lang="css">
@media screen and (min-width: 1000px) {
  .p-button-success {
    background: #C40380 !important;
    height: 45px !important;
    margin: 35px 0px 0px 436px !important;
    border: 1px solid #C40380 !important;
  }

  .p-dialog {
    justify-content: center !important;
  }
}
@media screen and (max-width: 600px) {
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td.action_border {
    border: 1px solid #EEEEEE !important;
  }
}

@media screen and (max-width: 1000px) {
  .p-datatable.p-datatable-responsive .p-datatable-tbody > tr > td.action_border {
    border: 1px solid #EEEEEE !important;
  }
}
</style>